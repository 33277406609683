import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Loading from "./src/Components/Loading";
const App = React.lazy(() => import('./App')); 

ReactDOM.render(
  <React.Suspense fallback={<Loading/>}>
      <App />
  </React.Suspense>,
  document.getElementById("root")
);
